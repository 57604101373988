import React from 'react';
import {Col, Container, Row, Carousel} from "react-bootstrap";
import {checkData} from "../../helper";
import {mediaBaseURL} from "../../utils/constant";


function Awardbadge({data}) {
    return (
        <div className="tekrevol_awardbadge p-100" id="awards">
            <Container className="h-100">
                <Row className="h-100">
                    <Col xs={12} md={5} lg={5} xl={5} xxl={5} className="my-auto">
                        <h2>{checkData(data, 'awards_title')}</h2>
                        <div
                            dangerouslySetInnerHTML={{__html: checkData(data, 'awards_description')}}/>
                    </Col>
                    <Col xs={12} md={7} lg={6} xl={6} xxl={6} className="offset-lg-1 my-auto">
                        <Carousel fade controls={false} indicatorLabels={false}>
                            {data && data.awards_badges ? data.awards_badges.map((el, i) => (
                                <Carousel.Item>
                                    <div className="awardbadge">
                                        <div className="awardbadge_item">
                                            <div className="star_item">
                                                <svg width="48" height="46" viewBox="0 0 48 46" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M24 0L29.6129 17.2746H47.7764L33.0818 27.9508L38.6946 45.2254L24 34.5491L9.30537 45.2254L14.9182 27.9508L0.223587 17.2746H18.3871L24 0Z"
                                                        fill="#FFB800"/>
                                                </svg>
                                                {el.Badge_rating}/5
                                            </div>
                                            <div className="star_title">
                                                {el.title}
                                            </div>
                                        </div>
                                        <div className="awardbadge_img">
                                            <img
                                                src={`${mediaBaseURL}${checkData(el, 'badge_image')}`}
                                                className="img-fluid"
                                                alt={checkData(el, 'title')}/>
                                        </div>
                                    </div>
                                </Carousel.Item>
                            )) : ''}

                        </Carousel>

                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Awardbadge;